<!--
 * @Author: zhangsheng zhangsheng@whty.com.cn
 * @Date: 2022-12-08 18:46:36
 * @LastEditors: fangting fangting@whty.com.cn
 * @LastEditTime: 2023-01-09 10:59:40
 * @FilePath: \web\src\views\invoicing\negativeElectronicInvoice\edit.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<add-invoice :title='title' :typeId='typeId' :category="category" :reopen='reopen'/>
</template>

<script>
import AddInvoice from '../modules/addInvoice.vue'
export default {
	name: 'ordinary',
	components: {
		AddInvoice,
	},
  data(){
    return{
        // title: '增值税电子普通发票',
        title:'',
        typeId : '02',  // 01 普票 02 专票
        category : '',
        reopen:''//重开
    }
  },
  methods: {
		
  },
  beforeMount(){
     if(this.$route.query.category=='1'){
      this.category = '1'
    }else{
      this.category = '2'
    }
    if(this.$route.query.reopen=='1'){
      this.reopen = '1'
    }
  },
  mounted(){
    if(this.$route.query.typeId == '01'){
      this.typeId = '01'
      this.title ='增值税电子普通发票';
    }else if(this.$route.query.typeId == '02'){
      this.typeId = '02'
      this.title ='增值税电子专用发票';
    }
  }
}
</script>